
import Vue from 'vue';
import Component from 'vue-class-component';
import { Slider, SliderItem } from 'vue-easy-slider';

@Component({
  components: {
    Slider,
    SliderItem,
  },
})
export default class MarketingHowWorks extends Vue {}
